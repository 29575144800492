// Importing required modules
import React from 'react';
import './App.scss';
import { Link, Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Importing Components
import Mainpage from "./Mainpage/Mainpage";
import Projects from "./Projects/Projects";
import ContactForm from "./ContactForm/ContactForm";
import About from "./About/About";
import Skills from "./Skills/Skills";
import LoadingOverlay from './LoadingOverlay/LoadingOverlay';
import pdf from "./Assets/resumeGurnihalSingh.pdf";
import { getJSONData } from "./Tools/Toolkit";

const START_SCRIPT:string = "https://still-refuge-22594.herokuapp.com/turnon";

const App = ():JSX.Element => {

  // To check if the viewport size is mobile or tab to change navigation style
  // and turn mobile nav off
  const isMobileorTab = useMediaQuery({
    query: '(max-device-width: 960px)'
  })

  // Set to open navigation menu on mobile or tab
  const openMenu = ():void => {
    setShowNav(!showNav);
  }

  

  // Sets the loading overlay and navigation number to display the background scribble
  const setLoadAndNav = (num:number):void => {
    setLoading(true);
    setNavNum(num);
    setInterval(() => setLoading(false), 2000);
    setShowNav(false);
  }

  const onResponse = (result:any):void => {
    console.log(result);
  };

  // This is called when a error occurs during a json transaction
  const onError = ():void => console.log("*** Error has occured during AJAX data transmission");

  // Use state variables
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showNav, setShowNav] = React.useState<boolean>(false);
  const [navNum, setNavNum] = React.useState<number>(0);

  React.useEffect(() => {
    getJSONData(START_SCRIPT, onResponse, onError);
  }, []);

  return (
    <div className="main">
      {/* Loading overlay is enabled every time new component is loaded */}
      <LoadingOverlay  enabled={loading} bgColor="#DADED4" spinnerColor="#FFFFFF" setLoading={setLoading}/>

      {/* This header is for mobile and tabs. Display is none for bigger screens */}
      <div className="header">
        <div className="header__title"><img src="logogs.svg" alt="dsf" height="152px" width="142px"></img></div>
        <div className="header__menuBtn"><img className="header__menuBtn__icon" src="menuredysvg.svg" alt="dsf" height="50px" width="50px" onClick={openMenu}></img></div>
      </div>

      {/* Using react-responsive package to check the screen size and only display mobile navigation if size is right */}
      {isMobileorTab &&
        <div className="shortNav" style={{display: (showNav ? 'flex' : 'none')}}>
          <Link to="/" className={"shortNav__link "  + (navNum === 1 ? 'click' : '')} onClick={(e) => setLoadAndNav(1)}>Home</Link>
          <Link to="/projects" className={"shortNav__link "  + (navNum === 2 ? 'click' : '')} onClick={(e) => setLoadAndNav(2)}>Projects</Link>
          <Link to="/skills" className={"shortNav__link "  + (navNum === 3 ? 'click' : '')} onClick={(e) => setLoadAndNav(3)}>Skills&nbsp;<i className="far fa-arrow-alt-circle-right"></i></Link>
          {/* <Link to="/projects" className={"shortNav__link "  + (navNum === 4 ? 'click' : '')} onClick={(e) => setLoadAndNav(4)}>Resume</Link> */}
          {/* <a className="shortNav__link" href={pdf} target="_blank" rel="noreferrer">Resume&nbsp;< i className="fas fa-download"></i></a> */}
          <Link to="/about" className={"shortNav__link "  + (navNum === 5 ? 'click' : '')} onClick={(e) => setLoadAndNav(5)}>About</Link>
          <Link to="/contact" className={"shortNav__link "  + (navNum === 6 ? 'click' : '')} onClick={(e) => setLoadAndNav(6)}>Contact</Link>
          <span className="shortNav__link" onClick={(e) => setShowNav(false)}><i className="fas fa-times"></i></span>
        </div>
}
      
      {/* This is the main body of the app. Everything is in it. */}
      <div className="subSection">
        <div className="nav">
          <div className="header__title"><img src="logogs.svg" alt="dsf" height="152px" width="142px"></img></div>
          <Link to="/" className={"nav__link "  + (navNum === 1 ? 'click' : '')} onClick={(e) => setLoadAndNav(1)}>Home</Link>
          <Link to="/projects" className={"nav__link "  + (navNum === 2 ? 'click' : '')} onClick={(e) => setLoadAndNav(2)}>Projects</Link>
          <Link to="/skills" className={"nav__link "  + (navNum === 3 ? 'click' : '')} onClick={(e) => setLoadAndNav(3)}>Skills&nbsp;<i className="far fa-arrow-alt-circle-right"></i></Link>
          {/* <Link to="/projects" href={pdf} target="_blank" className={"nav__link "  + (navNum === 4 ? 'click' : '')} onClick={(e) => setLoadAndNav(4)}>Resume&nbsp;< i className="fas fa-download"></i></Link> */}
          {/* <a className="nav__link" href={pdf} target="_blank" rel="noreferrer">Resume&nbsp;< i className="fas fa-download"></i></a> */}
          <Link to="/about" className={"nav__link "  + (navNum === 5 ? 'click' : '')} onClick={(e) => setLoadAndNav(5)}>About</Link>
          <Link to="/contact" className={"nav__link "  + (navNum === 6 ? 'click' : '')} onClick={(e) => setLoadAndNav(6)}>Contact</Link>
        </div>

        {/* This is the section which changes everytime from the navigation */}
        <div className="changesection">
          <Switch>
              <Route 
                path="/"
                render={() => <Mainpage navNumber={setLoadAndNav} />}
                exact
              />
              
              <Route 
                path="/main"
                render={() => <Mainpage navNumber={setLoadAndNav} />}
              />

              <Route 
                path="/projects"
                render={() => <Projects />}
              />

              <Route 
                path="/contact"
                render={() => <ContactForm setLoading={setLoading}/>}
              />

              <Route 
                path="/about"
                render={() => <About />}
              />

              <Route 
                path="/skills"
                render={() => <Skills />}
              />

              {/* <Route 
                path="/resume"
                render={() => <CourseEdit courses={courses} setLoading={setLoading} setRefreshData={setRefreshData} />}
              />

              <Route 
                path="/deleteCourse/:id"
                render={() => <DeleteCourse courses={courses} setLoading={setLoading} setRefreshData={setRefreshData} />}
              /> */}

              {/* <Route component={Error} /> */}

          </Switch>
        </div>

        {/* This social media widgets are placed here as they appear on all the pages */}
        <div className="socialMedia">
          <a href="https://www.instagram.com/gurnihalsandhu/" target="blank" >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="insta">
              <path id="insta_2" d="M25.3883 9.14667C23.9833 9.08333 23.56 9.07 20 9.07C16.44 9.07 16.0183 9.08333 14.6133 9.14667C10.9967 9.31167 9.31167 11.025 9.14833 14.6117C9.08333 16.0183 9.06833 16.44 9.06833 20C9.06833 23.56 9.08333 23.9817 9.14667 25.3883C9.31167 28.9683 10.99 30.6883 14.6117 30.8533C16.0167 30.9167 16.44 30.9317 20 30.9317C23.5617 30.9317 23.9833 30.9183 25.3883 30.855C29.005 30.69 30.6883 28.9733 30.8533 25.39C30.9167 23.9833 30.93 23.5617 30.93 20.0017C30.93 16.4417 30.9167 16.02 30.8533 14.615C30.6883 11.0267 29.0017 9.31167 25.3883 9.14667V9.14667ZM20 26.8467C16.2183 26.8467 13.1533 23.7817 13.1533 20C13.1533 16.2183 16.22 13.1533 20 13.1533C23.78 13.1533 26.8467 16.2183 26.8467 20C26.8467 23.7817 23.7817 26.8467 20 26.8467ZM27.1183 14.4833C26.235 14.4833 25.5183 13.7667 25.5183 12.8833C25.5183 12 26.235 11.2833 27.1183 11.2833C28.0017 11.2833 28.7183 12 28.7183 12.8833C28.7183 13.7667 28.0017 14.4833 27.1183 14.4833ZM24.445 20C24.445 22.455 22.455 24.445 20 24.445C17.545 24.445 15.555 22.455 15.555 20C15.555 17.545 17.545 15.555 20 15.555C22.455 15.555 24.445 17.545 24.445 20ZM31.6667 0H8.33333C3.73167 0 0 3.73167 0 8.33333V31.6667C0 36.2683 3.73167 40 8.33333 40H31.6667C36.27 40 40 36.2683 40 31.6667V8.33333C40 3.73167 36.27 0 31.6667 0V0ZM33.2533 25.4967C33.0333 30.345 30.335 33.0317 25.4983 33.2533C24.075 33.3183 23.6217 33.3333 20 33.3333C16.3783 33.3333 15.9267 33.3183 14.5033 33.2533C9.65667 33.0317 6.97 30.34 6.74667 25.4967C6.68167 24.075 6.66667 23.6217 6.66667 20C6.66667 16.38 6.68167 15.925 6.74667 14.5033C6.97 9.65667 9.66 6.96833 14.5033 6.74833C15.9267 6.68167 16.3783 6.66667 20 6.66667C23.6217 6.66667 24.075 6.68167 25.4983 6.74667C30.345 6.96833 33.0367 9.665 33.2533 14.5017C33.3183 15.925 33.3333 16.38 33.3333 20C33.3333 23.6217 33.3183 24.075 33.2533 25.4967V25.4967Z" fill="black"/>
              </g>
            </svg>
          </a>
          <a href="https://www.facebook.com/guri.sandhu47/" target="blank" >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="facebook">
              <path id="facebook_2" d="M31.6667 0H8.33333C3.73167 0 0 3.73167 0 8.33333V31.6667C0 36.2683 3.73167 40 8.33333 40H31.6667C36.27 40 40 36.2683 40 31.6667V8.33333C40 3.73167 36.27 0 31.6667 0ZM26.6667 11.6667H23.46C22.435 11.6667 21.6667 12.0867 21.6667 13.1483V15H26.6667L26.27 20H21.6667V33.3333H16.6667V20H13.3333V15H16.6667V11.795C16.6667 8.425 18.44 6.66667 22.435 6.66667H26.6667V11.6667Z" fill="black"/>
              </g>
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/gurnihal-singh-195b321b8/" target="blank" >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="linkedin">
              <path id="linkedin_2" d="M31.6667 0H8.33333C3.73167 0 0 3.73167 0 8.33333V31.6667C0 36.2683 3.73167 40 8.33333 40H31.6667C36.27 40 40 36.2683 40 31.6667V8.33333C40 3.73167 36.27 0 31.6667 0ZM13.3333 31.6667H8.33333V13.3333H13.3333V31.6667ZM10.8333 11.22C9.22333 11.22 7.91667 9.90333 7.91667 8.28C7.91667 6.65667 9.22333 5.34 10.8333 5.34C12.4433 5.34 13.75 6.65667 13.75 8.28C13.75 9.90333 12.445 11.22 10.8333 11.22ZM33.3333 31.6667H28.3333V22.3267C28.3333 16.7133 21.6667 17.1383 21.6667 22.3267V31.6667H16.6667V13.3333H21.6667V16.275C23.9933 11.965 33.3333 11.6467 33.3333 20.4017V31.6667Z" fill="black"/>
              </g>
            </svg>
          </a>
          <a href="https://github.com/Guri-Sandhu" target="blank" >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="github">
              <path id="github_2" d="M31.6667 0H8.33333C3.73167 0 0 3.73167 0 8.33333V31.6667C0 36.2683 3.73167 40 8.33333 40H31.6667C36.27 40 40 36.2683 40 31.6667V8.33333C40 3.73167 36.27 0 31.6667 0ZM24.2233 32.65C23.5483 32.78 23.3333 32.365 23.3333 32.01V28.3517C23.3333 27.1067 22.8967 26.2967 22.4167 25.8833C25.3867 25.5533 28.5067 24.425 28.5067 19.305C28.5067 17.8483 27.9867 16.6583 27.135 15.7267C27.2717 15.39 27.7283 14.0333 27.0033 12.1983C27.0033 12.1983 25.885 11.84 23.34 13.565C22.2733 13.265 21.1333 13.12 20 13.1133C18.8667 13.1183 17.7267 13.265 16.6617 13.5617C14.115 11.8367 12.995 12.195 12.995 12.195C12.2717 14.0317 12.7283 15.3867 12.8667 15.725C12.0133 16.6583 11.4933 17.8467 11.4933 19.3033C11.4933 24.41 14.605 25.555 17.5683 25.8933C17.1867 26.2267 16.8417 26.8133 16.7217 27.6767C15.96 28.0167 14.0317 28.605 12.8417 26.5667C12.8417 26.5667 12.1367 25.2867 10.7967 25.1917C10.7967 25.1917 9.49667 25.175 10.705 26.0033C10.705 26.0033 11.58 26.4133 12.1867 27.9533C12.1867 27.9533 12.9583 30.3333 16.6667 29.5267V32.0083C16.6667 32.36 16.4517 32.7733 15.7867 32.65C10.4867 30.8883 6.66667 25.89 6.66667 20C6.66667 12.635 12.6367 6.66667 20 6.66667C27.3633 6.66667 33.3333 12.635 33.3333 20C33.3333 25.8883 29.5183 30.885 24.2233 32.65V32.65Z" fill="black"/>
              </g>
            </svg>
          </a>

        </div>
      </div>

      {/* The footer is also displayed on all the pages */}
      <div className="footer">
        <span>
        &copy; 2021 &nbsp;  
         <a href="https://gurnihalsingh.com/" className="white-text">Gurnihal Singh</a>.
        </span>
      </div>
    </div>
  );
}

export default App;
