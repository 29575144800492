import React from 'react';
import { useMediaQuery } from 'react-responsive';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { useForm } from "react-hook-form";
// Importing Components
import "./ContactForm.scss";
import MapContact from "./MapContact";
import SmallMap from "./SmallMap";
import {contactPageProps} from "./../Tools/data.model"



import {sendJSONData} from "./../Tools/Toolkit";

const SUBMIT_SCRIPT:string = "https://still-refuge-22594.herokuapp.com/post";
// const RE_EMAIL:RegExp = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g);
const CHECK_EMAIL:RegExp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

// This is the contact page component
// It also uses mapbox to display the location
const ContactForm = ({setLoading}:contactPageProps):JSX.Element => {

    const { register, handleSubmit, watch, errors } = useForm();

    // let history = useHistory();

    // // isolate the route parameter and store in id variable
    // let { id } = useParams<{id:string}>();

    // Using this package to check screen size
    const isMobileorTab = useMediaQuery({
        query: '(max-device-width: 960px)'
    })

    function onSubmitClick(e:any):void {
        console.log(senderName);
        console.log(senderEmail);
        console.log(senderMessage);
        e.preventDefault();
    
        if(senderName !== "" && senderMessage !== "" && senderEmail !== "") {
            if(CHECK_EMAIL.test(senderEmail)){
                setShowError(false);
                setShowEmailError(false);
    
                let sendfile:any = {
                    "name": senderName,
                    "email": senderEmail,
                    "message": senderMessage
                }
    
                console.log(sendfile);
                let sendString:string = JSON.stringify(sendfile);
                // setLoading(true);
                sendJSONData(SUBMIT_SCRIPT, sendString, onsubResponse, onError, "POST");
                alertify.error("Processing... Please Wait...");
                console.log("sending successful");
                //loadingOverlayinDiv.style.display = "block";
            } else {
                setShowEmailError(true);
            }
        } else {
            console.log("Please fill all fields");
            setShowError(true);
        }
    }

    function onsubResponse(responseText:string):void {
        // console.log("This is" + responseText);
        // setLoading(false);
        setSenderName("");
        setSenderEmail("");
        setSenderMessage("");
        alertify.success("Message Sent Successfully");
        // history.push('/contact/2');

    }

    function onError(message:string):void {
        console.log("*** Error has occured during AJAX data transmission: " + message);
    }

    function handleEmailError(e:any):void {
        if(e.target.value === ""){
            console.log("nothing in it.")

        } else {
        
        }

    }

    // React.useEffect(():void => {
    //     if (id === "1") {
    //         console.log("normal load");
    //         // setParamID(1);
    //     } else {
    //         setSuccessMessage(true);
    //     }
    //   }, [id]);

    const [senderName, setSenderName] = React.useState<string>("");
    const [senderEmail, setSenderEmail] = React.useState<string>("");
    const [senderMessage, setSenderMessage] = React.useState<string>("");
    const [showError, setShowError] = React.useState<boolean>(false);
    const [showEmailError, setShowEmailError] = React.useState<boolean>(false);
    const [showEmailRed, setShowEmailRed] = React.useState<boolean>(true);
    const [showNameRed, setShowNameRed] = React.useState<boolean>(true);
    const [showMessageRed, setShowMessageRed] = React.useState<boolean>(true);

    return (
        <div className="contact">
            <div className="contact__leftSide">
                <form className="form" onSubmit={e => onSubmitClick(e)}>
                    <h2 className="formHeading">CONTACT ME</h2>
                    {/* <label className="form__successlbl" id="successTxt" style={{display: (successMessage ? 'block' : 'none')}}>"Message Sent successfully"</label> */}
                    <p>Name:<input placeholder="Write your name here.." required maxLength={50} className={showNameRed ? '' : 'error'} value={senderName} onChange={(e) => setSenderName(e.target.value)}></input></p>
                    <p>Email:<input placeholder="Let me know how to contact you back.." maxLength={50} required className={showEmailRed ? '' : 'error'} value={senderEmail} onChange={e => setSenderEmail(e.target.value)}></input></p>
                    <label className="form__errorlbl" id="errorTxt" style={{display: (showEmailError ? 'block' : 'none')}}>Error in email Address</label>
                    <p>Message:<input placeholder="What would you like to tell me.." maxLength={150} required className={showMessageRed ? '' : 'error'} value={senderMessage} onChange={e => setSenderMessage(e.target.value)}></input></p>
                    <label className="form__errorlbl" id="errorTxt" style={{display: (showError ? 'block' : 'none')}}>*Error: Please check your fields</label>
                    <input type="submit" className="sendForm" value="Send Message"></input>
                </form>
            </div>

            <div className="contact__rightSide">
                <div className="contact__rightSide--upper">
                    {isMobileorTab ?
                        <SmallMap />
                    :
                    <MapContact />
                    }
                    
                </div>
                {/* <div className="contact__rightSide--lower">
                    <div className="detailsHeading"><span className="fa fa-phone"></span>Let's Talk</div>
                    
                    <div className="details"></div>
                    <div className="detailsHeading"><span className="fa fa-envelope-o"></span>Email Me</div>
                    <div className="details"></div>
                </div> */}
            </div>
        </div>
    );
}

export default ContactForm;