import React from 'react';
import { Link } from 'react-router-dom';

// Importing components
import Typing from "./Typing";
import "./Mainpage.scss";

// Importing parameter datatypes
import {mainPageProps} from "./../Tools/data.model";

// Mainpage component. The animated bobblehead and data on main page
const Mainpage = ({navNumber}:mainPageProps):JSX.Element => {

    // To change navigation number in app.tsx to display scribble on navigation
    const changeNav = (num:Number):void => {
        navNumber(num);
    }

    return (
        <div className="content">
            <div className="content__name">
                <div className="mainText">Hi, My name is <span className="name">Gurnihal Singh</span>. I am a <Typing /></div>
                
                <div className="content__buttons">
                    <Link to="/projects"><button className="btn-1" onClick={(e) => changeNav(2)}>View My Work</button></Link>
                    <Link to="/contact"><button className="login-btn" onClick={(e) => changeNav(4)}>Let's Talk</button></Link>
                </div>
            </div>

            <div className="content__bobble">
                <svg width="250" height="600" viewBox="0 0 250 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="bobbleheadDone">
                <g id="standboard">
                <path id="Vector" d="M58.391 406.928L33.8445 479.088L33.8069 484.234L38.4225 484.255L39.1411 511.004C39.1411 511.004 37.8818 514.728 41.6087 513.656L213.302 510.809C213.302 510.809 215.763 510.803 215.788 508.146L218.459 480.501L222.765 479.992C222.765 479.992 224.615 481.582 224.033 474.674L190.109 404.941L58.391 406.928Z" fill="#C69C6D" stroke="black" strokeMiterlimit="10"/>
                <path id="Vector_2" d="M38.0901 484.348L218.206 480.563" stroke="black" strokeMiterlimit="10"/>
                <path id="Vector_3" d="M223.903 474.694L34.0335 479.176" stroke="black" strokeMiterlimit="10"/>
                </g>
                <g id="body">
                <path id="Vector_4" d="M184.217 427.064C184.217 427.064 186.554 438.512 184.108 441.911C184.108 441.911 182.223 451.134 160.412 452.588C160.412 452.588 148.29 454.964 129.661 436.511L128.46 434.012L125.11 436.892C125.11 436.892 107.392 459.169 92.313 453.186C92.313 453.186 75.9563 454.698 73.0581 437.223C73.1719 433.81 73.8117 430.444 74.9494 427.273C74.9494 427.273 82.4177 400.763 93.3522 395.882C128.318 415.321 128.389 409.137 128.389 409.137C156.287 405.717 163.891 391.979 163.891 391.979L172.876 404.529C172.876 404.529 183.707 414.594 184.217 427.064ZM164.098 402.585C164.483 402.355 164.807 402.014 165.034 401.598C165.261 401.181 165.383 400.704 165.388 400.216C165.374 399.717 165.22 399.235 164.95 398.836C164.679 398.438 164.305 398.143 163.878 397.991C163.084 397.81 162.26 397.913 161.522 398.287C158.995 399.291 156.661 400.818 154.641 402.787L154.144 403.506C154.077 403.816 154.072 404.138 154.13 404.45C154.188 404.762 154.307 405.056 154.478 405.31C154.65 405.565 154.87 405.774 155.122 405.923C155.375 406.071 155.653 406.156 155.939 406.17C156.978 406.17 157.875 405.299 158.824 404.651C159.765 403.99 160.803 403.517 161.89 403.255C162.653 403.148 163.397 402.915 164.098 402.563V402.585ZM103.422 411.556L103.222 410.476C102.17 410.361 101.234 409.533 100.311 408.863C98.8038 407.753 97.1179 406.98 95.3468 406.588C94.3204 406.365 93.0424 406.422 92.5195 407.668C93.2619 408.67 94.1974 409.472 95.2564 410.015L99.2584 412.463C99.951 412.978 100.752 413.283 101.582 413.349C102.03 413.33 102.457 413.139 102.793 412.808C103.128 412.478 103.35 412.027 103.422 411.534V411.556Z" fill="#A3BCB6"/>
                <path id="Vector_5" d="M184.225 275.226L184.909 277.307C184.909 277.307 182.927 279.194 184.728 280.871C184.638 281.415 184.502 281.947 184.321 282.462C183.96 283.528 183.308 284.622 182.282 284.169L181.636 285.825C180.694 287.034 165.125 278.452 165.125 278.452C166.37 281.62 160.671 300.181 160.671 300.181C159.112 301.675 157.469 303.055 155.752 304.314C128.067 324.474 100.776 294.63 100.776 294.63C100.776 294.63 93.6048 282.57 93.9404 277.732L93.6758 277.869L93.0819 275.572C90.6296 274.329 87.926 273.832 85.2457 274.132L84.1807 272.901C80.8129 273.085 77.438 272.773 74.1434 271.972L73.7045 271.886C73.7045 271.886 71.8906 271.036 73.7432 266.896V262.742C73.7432 262.742 73.3753 236.988 84.9424 225.886C84.9424 225.886 105.598 214.87 118.888 218.318L133.089 217.836C154.584 212.443 174.833 226.735 174.833 226.735C174.833 226.735 185.496 256.744 184.812 267.537C184.812 267.537 185.373 273.354 184.166 275.003L184.225 275.226Z" fill="#39603D"/>
                <path id="Vector_6" d="M165.124 278.452C165.124 278.452 180.693 287.055 181.661 285.853C181.545 286.335 177.84 301.693 173.019 308.173L170.604 307.316C170.604 307.316 161.858 302.665 160.67 300.159C160.67 300.159 166.37 281.598 165.124 278.452Z" fill="#E4A57A"/>
                <path id="Vector_7" d="M173.6 311.499C173.6 311.499 174.194 313.162 172.354 315.639L170.766 315.502C167.9 311.283 163.22 310.664 159.631 307.669C158.737 306.897 157.9 306.047 157.127 305.127L155.752 304.292C157.469 303.033 159.112 301.653 160.671 300.159C161.858 302.665 170.605 307.316 170.605 307.316L173.6 311.499Z" fill="#3C403D"/>
                <path id="Vector_8" d="M172.354 315.639L171.708 317.295L161.871 336.31L161.258 337.966C161.258 337.966 161.206 344.611 159.967 346.26C159.967 346.26 157.979 369.501 162.097 384.491L164.479 390.323L163.833 391.979C163.833 391.979 156.204 405.716 128.332 409.136C128.332 409.136 128.261 415.321 93.2948 395.881L94.5212 394.233L95.7541 390.921L94.5793 386.759C94.5793 386.759 94.5793 385.924 97.6518 380.142C97.6518 380.142 97.1419 367.672 98.3554 367.687L98.5749 338.614C97.8994 337.555 97.3014 336.438 96.7869 335.273C96.7869 335.273 86.5753 324.379 86.6334 316.906C86.6334 316.906 84.826 316.056 85.4521 312.737C85.8214 312.466 86.1264 312.099 86.341 311.668C86.5557 311.236 86.6737 310.754 86.685 310.26C86.685 310.26 98.9105 294.594 100.731 294.608C100.731 294.608 128.022 324.48 155.707 304.292L157.082 305.127C157.855 306.047 158.692 306.897 159.586 307.668C163.175 310.664 167.855 311.268 170.721 315.502L172.354 315.639Z" fill="#3C403D"/>
                <path id="Vector_9" d="M165.376 400.195C165.371 400.682 165.248 401.159 165.021 401.576C164.794 401.993 164.47 402.334 164.085 402.563C163.381 402.927 162.633 403.169 161.864 403.283C160.777 403.546 159.74 404.019 158.798 404.68C157.869 405.328 156.952 406.178 155.913 406.199C155.628 406.185 155.349 406.1 155.096 405.951C154.844 405.803 154.624 405.594 154.453 405.339C154.281 405.084 154.162 404.791 154.105 404.479C154.047 404.167 154.051 403.845 154.118 403.535L154.615 402.815C156.635 400.847 158.97 399.32 161.496 398.315C162.234 397.942 163.059 397.839 163.852 398.02C164.275 398.166 164.647 398.453 164.919 398.841C165.192 399.23 165.351 399.702 165.376 400.195Z" fill="#A67C52"/>
                <path id="Vector_10" d="M103.222 410.454L103.422 411.534C103.347 412.023 103.123 412.469 102.788 412.795C102.453 413.122 102.027 413.309 101.583 413.327C100.752 413.262 99.9515 412.957 99.2589 412.441L95.2569 409.994C94.1979 409.451 93.2624 408.648 92.52 407.646C93.0429 406.401 94.3209 406.343 95.3473 406.566C97.1184 406.959 98.8043 407.731 100.311 408.842C101.234 409.49 102.17 410.339 103.222 410.454Z" fill="#A67C52"/>
                <path id="Vector_11" d="M93.9401 277.732C93.598 282.541 100.776 294.58 100.776 294.58C98.9555 294.58 86.73 310.232 86.73 310.232H84.9162C84.9162 310.232 78.3194 301.866 74.2012 285.63C78.1193 286.048 91.6293 278.963 93.6754 277.89L93.9401 277.732Z" fill="#E4A57A"/>
                <path id="Vector_12" d="M93.0816 275.572L93.6754 277.868C91.6679 278.97 78.1127 286.055 74.2011 285.651C74.2011 285.651 72.9811 286.883 73.646 279.416C73.646 279.416 71.8451 276.904 72.4647 275.248C72.4647 275.248 71.858 275.248 73.704 271.943L74.143 272.029C77.4376 272.83 80.8125 273.142 84.1803 272.958L85.2454 274.189C87.921 273.871 90.6245 274.348 93.0816 275.572Z" fill="#39603D"/>
                <path id="Vector_13" d="M181.662 285.824L182.307 284.168C183.333 284.651 183.985 283.527 184.347 282.462C184.527 281.947 184.663 281.414 184.753 280.871C182.953 279.193 184.934 277.307 184.934 277.307L184.25 275.226L184.192 275.046C185.418 273.397 184.837 267.58 184.837 267.58C185.522 256.78 174.858 226.778 174.858 226.778C174.858 226.778 154.609 212.486 133.115 217.879L118.914 218.361C105.617 214.913 84.9679 225.929 84.9679 225.929C73.4008 237.031 73.7687 262.785 73.7687 262.785V266.939C71.9162 271.079 73.73 271.928 73.73 271.928C71.8839 275.233 72.4906 275.233 72.4906 275.233C71.8452 276.889 73.6719 279.402 73.6719 279.402C73.0264 286.868 74.227 285.637 74.227 285.637C78.3452 301.873 84.9421 310.239 84.9421 310.239H86.7559C86.7446 310.732 86.6265 311.215 86.4119 311.646C86.1972 312.077 85.8922 312.444 85.523 312.716C84.8775 316.035 86.7042 316.884 86.7042 316.884C86.6461 324.358 96.8577 335.251 96.8577 335.251C97.3723 336.417 97.9702 337.534 98.6457 338.592L98.4262 367.665C97.2127 367.665 97.7227 380.121 97.7227 380.121C94.6566 385.881 94.6502 386.738 94.6502 386.738L95.8249 390.899L94.5921 394.211L93.3656 395.86C82.4311 400.741 74.9629 427.251 74.9629 427.251C73.8251 430.422 73.1854 433.788 73.0716 437.201C75.9698 454.676 92.3264 453.164 92.3264 453.164C107.418 459.125 125.143 436.863 125.143 436.863L128.493 433.983L129.687 436.482C148.316 454.935 160.438 452.559 160.438 452.559C182.249 451.119 184.134 441.881 184.134 441.881C186.58 438.483 184.243 427.035 184.243 427.035C183.734 414.565 172.915 404.5 172.915 404.5L163.93 391.95L164.576 390.294L162.194 384.462C158.076 369.472 160.064 346.231 160.064 346.231C161.29 344.582 161.355 337.937 161.355 337.937L161.968 336.281L171.74 317.331L172.386 315.675C174.226 313.198 173.632 311.535 173.632 311.535L170.637 307.352L173.051 308.209C177.873 301.729 181.578 286.35 181.694 285.889" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_14" d="M86.73 310.261C86.73 310.261 98.9555 294.594 100.776 294.608C100.776 294.608 93.598 282.57 93.9401 277.76L93.6754 277.897C91.668 278.97 78.1128 286.055 74.2012 285.651" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_15" d="M170.604 307.316C170.604 307.316 161.858 302.665 160.67 300.159C160.67 300.159 166.37 281.598 165.124 278.43C165.124 278.43 180.693 287.034 181.661 285.832" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_16" d="M163.904 391.979C163.904 391.979 156.275 405.717 128.403 409.137C128.403 409.137 128.332 415.321 93.3657 395.882" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_17" d="M100.776 294.608C100.776 294.608 128.067 324.481 155.752 304.292C157.469 303.033 159.112 301.652 160.67 300.159" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_18" d="M161.6 388.689C157.663 392.671 152.835 394.506 148.123 396.27L130.598 402.83" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_19" d="M127.228 404.119C121.006 402.974 114.744 401.807 108.806 399.079C104.823 397.235 100.912 394.644 96.7095 394.471" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_20" d="M128.854 346.563C128.306 347.33 127.795 348.131 127.325 348.96C125.337 351.963 121.877 352.841 120.36 356.326" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_21" d="M128.732 341.4C126.683 344.018 124.509 346.511 122.219 348.867" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_22" d="M129.229 333.783C126.582 335.835 123.949 337.887 121.302 339.931" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_23" d="M127.964 331.414C125.281 332.561 122.746 334.098 120.425 335.986" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_24" d="M129.635 312.709C128.593 317.245 128.796 322.026 130.216 326.432" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_25" d="M138.621 313.659C138.552 317.085 138.265 320.502 137.762 323.883" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_26" d="M133.057 342.178C134.309 343.539 135.09 345.547 136.11 347.218C137.13 348.888 138.66 350.364 140.228 349.932" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_27" d="M130.539 329.571C129.087 329.283 127.925 327.828 126.795 326.54C125.109 324.601 123.244 322.866 121.231 321.363" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_28" d="M157.127 305.127C157.9 306.047 158.738 306.897 159.632 307.669C163.221 310.664 167.9 311.268 170.766 315.502" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_29" d="M164.272 306.229L159.754 306.539" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_30" d="M101.925 299.022C100.402 302.043 98.414 304.742 96.051 306.992C93.0624 309.807 89.5962 311.658 86.8335 314.912" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_31" d="M147.968 404.881C150.16 409.386 153.252 413.269 157.004 416.228" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_32" d="M155.287 418.562C151.934 417.687 148.489 417.336 145.05 417.518" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_33" d="M154.132 403.513C154.065 403.823 154.06 404.145 154.118 404.457C154.176 404.769 154.294 405.063 154.466 405.317C154.637 405.572 154.857 405.781 155.11 405.93C155.362 406.078 155.641 406.163 155.926 406.177C156.965 406.177 157.863 405.306 158.811 404.658C159.753 403.997 160.79 403.524 161.877 403.261C162.646 403.147 163.394 402.905 164.098 402.541C164.484 402.312 164.807 401.971 165.034 401.554C165.261 401.137 165.384 400.66 165.389 400.173C165.374 399.673 165.221 399.191 164.95 398.793C164.68 398.395 164.305 398.099 163.878 397.948C163.085 397.766 162.26 397.87 161.522 398.243C158.996 399.248 156.661 400.774 154.641 402.743" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_34" d="M160.05 406.941C162.329 405.788 164.68 404.825 167.086 404.061C167.179 404.012 167.282 403.99 167.385 403.998C167.488 404.005 167.587 404.042 167.674 404.104C168.067 404.514 167.551 405.314 167.093 405.58L162.277 408.417C161.109 409.137 159.896 409.806 158.624 409.785C158.484 409.798 158.343 409.772 158.214 409.711C158.084 409.649 157.97 409.554 157.882 409.432C157.781 409.22 157.742 408.978 157.768 408.74C157.795 408.502 157.886 408.278 158.03 408.1C158.304 407.813 158.643 407.616 159.011 407.531" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_35" d="M112.168 407.97C109.34 409.726 106.999 412.316 105.404 415.458" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_36" d="M104.3 418.72C106.553 420.768 109.244 422.125 112.111 422.658" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_37" d="M108.554 405.89C105.304 404.211 101.959 402.768 98.5424 401.57C98.2999 401.443 98.0364 401.374 97.7686 401.366C97.5009 401.359 97.2346 401.413 96.9868 401.527C96.8609 401.618 96.7532 401.738 96.67 401.877C96.5867 402.017 96.5298 402.174 96.5026 402.339C96.4755 402.504 96.4786 402.673 96.5119 402.837C96.5452 403 96.6079 403.155 96.6963 403.29C96.7964 403.387 96.9127 403.46 97.0384 403.506L105.397 407.25C106.288 407.646 107.528 407.848 107.915 406.682" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_38" d="M103.222 410.454C102.17 410.339 101.234 409.511 100.311 408.842C98.8043 407.731 97.1184 406.959 95.3473 406.566C94.3209 406.343 93.0429 406.401 92.52 407.646C93.2624 408.648 94.1979 409.451 95.2569 409.994L99.2589 412.441C99.9515 412.957 100.752 413.262 101.583 413.327C102.027 413.309 102.453 413.122 102.788 412.795C103.123 412.469 103.347 412.023 103.422 411.534" stroke="#A3BCB6" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_39" d="M127.221 426.503C122.277 430.295 117.328 434.089 112.375 437.886C110.823 439.191 109.137 440.286 107.353 441.147C106.062 441.709 104.681 441.99 103.383 442.587C101.55 443.48 99.9236 445.035 98.0969 445.964C92.3586 448.844 86.0328 445.107 80.8238 440.751C78.0999 438.462 75.2855 435.711 74.2915 431.514" stroke="#42210B" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_40" d="M129.751 427.129C140.357 436.374 152.285 443.509 164.846 444.481C168.661 444.776 172.592 444.481 176.155 442.638C178.083 441.641 179.812 440.223 181.24 438.468C182.668 436.713 183.766 434.657 184.469 432.421" stroke="#42210B" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_41" d="M183.514 265.327C181.139 269.474 177.117 271.72 173.315 271.036" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_42" d="M184.373 270.475C182.533 272.145 180.577 273.693 178.415 274.269C177.317 274.607 176.157 274.596 175.065 274.239C173.972 273.881 172.991 273.191 172.231 272.246" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_43" d="M184.282 275.176C184.26 275.188 184.24 275.206 184.224 275.226C182.347 276.823 180.115 277.819 177.769 278.106" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_44" d="M184.54 282.462H184.32C182.017 282.405 179.784 281.56 177.924 280.043" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_45" d="M166.977 269.186C168.461 270.381 169.92 271.641 171.334 272.973" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_46" d="M74.9438 279.992C78.4498 279.884 81.8872 278.884 84.994 277.069" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_47" d="M74.1431 272.015C77.4377 272.816 80.8125 273.128 84.1804 272.944" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_48" d="M93.0818 275.572C90.6294 274.329 87.9258 273.832 85.2456 274.132" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_49" d="M101.35 279.963C103.643 283.647 106.135 287.172 108.812 290.518" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_50" d="M99.7822 282.707C102.112 286.202 104.149 289.928 105.869 293.838" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_51" d="M130.016 332.35C130.997 345.497 128.079 358.551 127.55 371.647C127.409 375.117 127.446 378.593 127.66 382.058C127.866 385.37 128.241 388.668 128.383 391.98C128.66 398.546 128.086 405.105 127.737 411.65C127.331 419.166 127.234 426.769 128.57 434.149C128.686 434.804 128.957 434.718 129.073 434.149C129.214 433.293 129.197 432.413 129.022 431.564C127.899 425.329 128.105 418.929 128.447 412.614C128.802 406.084 129.332 399.547 129.144 392.995C128.957 386.443 128.021 379.905 128.247 373.296C128.473 366.686 129.306 360.257 129.971 353.755C130.732 346.325 131.262 338.845 130.694 331.371C130.694 331.119 130.623 329.895 130.222 330.111C129.822 330.327 130.003 332.062 130.022 332.365L130.016 332.35Z" fill="black"/>
                </g>
                <g id="face">
                <path id="Vector_52" d="M74.3374 184.353C74.3374 184.353 86.6016 252.075 127.913 247.374C127.913 247.374 170.515 257.072 181.488 181.473L183.424 179.314L186.652 166.354L192.196 149.679L194.398 141.154L192.461 131.074L189.234 127.474H186.045L184.07 129.634L186.006 111.635V90.0352L185.361 86.4353L183.424 72.7555L179.551 69.1556L182.133 61.9557C182.133 61.9557 185.361 46.836 169.869 31.7163C169.869 31.7163 153.087 15.8766 149.214 15.1566C149.214 15.1566 136.949 14.4366 138.24 12.2766C138.24 12.2766 138.886 14.3502 127.913 10.7935L121.458 12.2766L114.357 14.4366L109.839 15.8766H106.612L104.675 16.5965L102.093 18.0365L98.2204 18.7565L95.6384 20.1965L93.0565 22.3564L90.4746 23.7964L85.3107 25.2364L84.0197 27.3963L81.4378 28.8363L79.5013 31.7163L77.5649 33.8762L75.6284 34.5962L74.3374 36.965L73.0465 40.3561L70.4645 41.0761L67.2371 47.556L64.6552 55.4758V61.8693L66.5916 64.1157L64.6552 65.6852L67.2371 67.7228L64.6552 70.2644V78.393L65.9461 83.5625L67.2371 87.8824L67.8826 92.9223L67.2371 105.162V112.362L69.1736 121.002L71.11 130.362C71.11 130.362 64.6552 127.309 64.6552 130.995C64.6552 130.995 62.7187 139.001 64.0097 142.601C64.1443 147.375 65.2477 152.052 67.2371 156.281C67.2371 156.281 67.2371 167.081 70.4645 167.801C70.4645 167.801 71.11 170.681 70.4645 172.841C70.4645 172.841 69.1736 178.601 71.7555 181.481L74.3374 184.353Z" fill="black" stroke="black" strokeMiterlimit="10"/>
                <path id="Vector_53" d="M180.197 133.234L181.41 154.834L179.551 168.427L174.923 184.015C174.923 184.015 169.869 194.433 166.642 195.873L159.541 199.473L153.732 187.233L145.986 182.194L137.64 178.932L128.984 176.218L122.103 176.434L115.648 177.874L111.13 181.474L103.384 185.074L100.802 192.273L98.3751 200.956L96.9292 208.113L84.665 188.673L74.9828 174.994L72.4008 164.914V149.679L71.1099 130.354L72.6655 113.161L79.1203 98.6318L81.4376 77.7954C81.4376 77.7954 85.956 64.1156 91.1199 64.1156L107.902 60.5157L123.394 57.6357L133.722 59.0757L138.886 57.6357L147.923 59.7957L156.314 62.0349L158.896 60.5157L165.351 65.5556L169.869 75.6354L174.917 84.4049L177.034 104.032L180.197 133.234Z" fill="#E4A57A" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_54" d="M137.873 114.313C137.873 114.313 136.35 108.395 140.991 107.603C140.991 107.603 141.507 104.773 165.416 111.073L174.918 118.244C170.67 121.254 137.873 114.313 137.873 114.313Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_55" d="M117.617 112.549C117.617 112.549 108.09 105.652 106.56 107.589C106.56 107.589 98.8596 105.061 79.1143 117.812C79.1143 117.812 106.16 122.96 117.617 112.549Z" fill="black" stroke="black" strokeWidth="0.75" strokeMiterlimit="10"/>
                <path id="Vector_56" d="M140.823 131.075C140.823 131.075 151.874 119.555 164.099 131.075C164.093 131.075 147.278 137.555 140.823 131.075Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_57" d="M112.738 131.434C112.738 131.434 98.4985 119.914 87.564 131.434C87.5446 131.434 100.474 137.914 112.738 131.434Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_58" d="M116.101 121.938C117.295 121.887 117.998 123.039 118.392 124.155C118.88 125.694 119.203 127.291 119.354 128.914C119.72 132.127 119.569 135.387 118.908 138.541C118.263 141.637 117.146 144.574 116.326 147.605C115.445 150.622 115.083 153.798 115.261 156.965C115.261 157.102 115.332 158.103 115.604 157.894C115.875 157.685 115.765 156.526 115.746 156.238C115.52 152.832 116.759 149.42 117.747 146.259C118.829 143.03 119.562 139.667 119.928 136.244C120.238 132.713 120.09 129.151 119.489 125.667C119.147 123.694 118.573 119.187 116.042 119.295C115.655 119.295 115.72 121.995 116.101 121.974V121.938Z" fill="black"/>
                <path id="Vector_59" d="M111.776 159.118C110.388 160.637 109.007 162.242 108.613 164.446C108.397 165.408 108.397 166.415 108.612 167.378C108.827 168.341 109.25 169.231 109.846 169.968C111.271 171.437 112.87 172.682 114.597 173.669C116.357 174.866 118.349 175.577 120.406 175.742C120.632 175.742 120.651 173.964 120.367 173.957C116.991 173.798 114.048 171.674 111.369 169.565C110.182 168.629 108.516 167.21 108.974 165.374C109.433 163.538 110.737 162.134 111.924 160.839C112.118 160.63 112.047 160.003 112.021 159.737C112.021 159.651 111.944 158.959 111.795 159.118H111.776Z" fill="black"/>
                <path id="Vector_60" d="M138.886 156.317L141.778 162.307C142.146 163.078 143.017 164.316 142.966 165.238C142.914 166.159 142.056 167.239 141.526 167.937C139.338 170.817 136.175 173.798 132.547 172.466C132.457 172.43 132.212 174.115 132.586 174.252C133.57 174.565 134.605 174.622 135.612 174.42C136.619 174.218 137.571 173.761 138.396 173.085C140.191 171.768 141.686 170 142.759 167.923C143.15 167.034 143.34 166.052 143.315 165.062C143.289 164.073 143.047 163.105 142.611 162.242C141.449 159.737 140.209 157.274 139.015 154.79C138.86 154.467 138.796 154.992 138.783 155.114C138.712 155.524 138.743 155.947 138.873 156.338L138.886 156.317Z" fill="black"/>
                <path id="Vector_61" d="M137.318 119.13C135.876 120.293 134.687 121.803 133.839 123.548C132.991 125.292 132.506 127.228 132.419 129.21C132.154 133.782 133.484 138.145 134.891 142.371C135.013 142.731 135.117 142.126 135.123 142.04C135.181 141.632 135.15 141.215 135.033 140.823C133.813 137.151 132.341 133.091 132.825 129.102C133.051 127.446 133.578 125.859 134.373 124.438C135.167 123.017 136.213 121.791 137.447 120.836C137.744 120.606 137.447 119.008 137.318 119.116V119.13Z" fill="black"/>
                <path id="Vector_62" d="M113.99 167.729C115.43 167.556 116.947 167.362 118.341 167.88C118.905 168.057 119.423 168.385 119.848 168.835C120.273 169.286 120.593 169.845 120.781 170.465C120.781 170.465 120.832 170.724 120.91 170.717C120.987 170.71 121.02 170.487 121.026 170.458C121.107 170.04 121.107 169.608 121.026 169.191C120.869 168.514 120.568 167.89 120.147 167.372C119.727 166.855 119.2 166.458 118.612 166.217C117.147 165.555 115.507 165.756 113.977 165.944C113.693 165.98 113.797 167.758 114.016 167.729H113.99Z" fill="black"/>
                <path id="Vector_63" d="M140.312 165.648C138.989 164.984 137.51 164.81 136.091 165.153C134.673 165.495 133.39 166.334 132.431 167.549C132.25 167.794 132.315 168.363 132.334 168.651C132.334 168.73 132.431 169.443 132.56 169.27C133.515 168.073 134.789 167.249 136.194 166.918C137.6 166.588 139.064 166.768 140.371 167.434C140.5 167.506 140.525 166.606 140.525 166.534C140.525 166.325 140.525 165.763 140.325 165.648H140.312Z" fill="black"/>
                <path id="Vector_64" d="M120.722 170.199C122.729 172.971 125.653 176.585 129.242 175.167C129.597 175.023 129.307 173.338 129.203 173.381C125.634 174.785 122.749 171.221 120.774 168.478C120.638 168.284 120.573 169.061 120.573 169.104C120.521 169.477 120.573 169.859 120.722 170.199Z" fill="black"/>
                <path id="Vector_65" d="M120.89 206.954C120.89 206.954 118.547 209.114 105.96 196.55C105.96 196.55 102.3 205.96 102.997 208.3C102.997 208.3 108.103 224.817 120.748 229.965C120.748 229.965 124.924 227.87 126.254 228.611L127.583 228.438C129.667 228.079 131.795 228.625 133.528 229.965C133.528 229.965 149.569 226.473 152.454 208.113C152.454 208.113 151.466 199.833 149.227 195.153C149.227 195.153 138.499 210.712 134.987 206.716C134.987 206.716 128.894 208.783 127.603 208.264C125.318 208.214 123.054 207.772 120.89 206.954Z" fill="#E4A57A"/>
                <path id="Vector_66" d="M145.102 199.725C143.656 201.58 142 203.218 140.177 204.599C138.486 205.881 136.343 207.357 134.239 206.486L134.316 210.086C135.543 209.272 137.021 209.538 138.344 209.049C139.649 208.484 140.837 207.629 141.836 206.536C144.302 204.067 146.632 201.41 149.021 198.84C149.408 198.422 149.259 197.162 149.221 196.636C149.221 196.435 149.092 195.103 148.762 195.398C145.37 198.441 141.711 201.094 137.841 203.318C135.964 204.471 133.998 205.435 131.967 206.198C129.829 206.912 127.568 207.039 125.376 206.565L125.46 210.165L135.575 209.322C136.079 209.279 136.04 205.795 135.498 205.723L134.852 205.672C134.433 205.672 134.329 209.186 134.93 209.272L135.575 209.33L135.498 205.73L125.376 206.572C124.879 206.608 124.899 210.021 125.46 210.172C127.699 210.658 130.01 210.514 132.186 209.754C134.257 208.962 136.261 207.969 138.176 206.788C142.016 204.552 145.648 201.899 149.021 198.868L148.762 195.427C146.439 197.932 144.154 200.467 141.772 202.929C140.776 204.053 139.58 204.931 138.26 205.507C136.969 205.996 135.465 205.73 134.239 206.543C133.723 206.889 133.723 209.87 134.316 210.143C136.408 211.007 138.512 209.553 140.203 208.3C142.117 206.882 143.853 205.187 145.367 203.26C145.735 202.792 145.606 201.633 145.561 201.057C145.561 200.906 145.374 199.48 145.102 199.826V199.725Z" fill="#D39972"/>
                <path id="Vector_67" d="M127.912 205.097C125.737 204.909 123.562 204.729 121.393 204.528C119.224 204.326 117.288 203.923 115.519 202.541C112.582 200.251 109.942 196.061 105.895 196.68C105.295 196.781 105.566 200.316 105.973 200.28C110.162 199.61 112.724 204.139 115.848 206.342C117.516 207.427 119.399 208.036 121.329 208.113C123.549 208.329 125.769 208.502 127.99 208.696C128.403 208.732 128.513 205.183 127.912 205.097Z" fill="#D39972"/>
                <path id="Vector_68" d="M105.966 195.153C105.966 195.153 116.255 184.181 119.825 185.707C119.825 185.707 126.622 189.012 127.913 187.399C127.913 187.399 125.899 189.653 134.974 185.563C134.974 185.563 146.632 193.713 149.214 192.993C149.214 192.993 144.966 196.125 132.567 193.836C132.567 193.836 132.567 193.713 122.181 195.153C122.181 195.153 113.944 192.993 111.246 195.153H105.966Z" fill="#DC8D88" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_69" d="M149.214 195.153C149.214 195.153 138.809 208.912 134.974 206.716C134.974 206.716 126.138 210.115 120.896 206.954C120.896 206.954 118.23 209.467 105.966 196.55C105.966 196.55 112.757 198.033 114.525 195.153C114.525 195.153 120.812 196.593 122.103 196.593L130.992 195.153L140.345 196.55L149.214 195.153Z" fill="#DC8D88" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_70" d="M99.1825 133.796C102.034 133.796 104.346 132.139 104.346 130.095C104.346 128.051 102.034 126.395 99.1825 126.395C96.3305 126.395 94.0186 128.051 94.0186 130.095C94.0186 132.139 96.3305 133.796 99.1825 133.796Z" fill="#8C6239" stroke="black" strokeWidth="0.57" strokeMiterlimit="10"/>
                <path id="Vector_71" d="M151.467 133.796C154.319 133.796 156.631 132.139 156.631 130.095C156.631 128.051 154.319 126.395 151.467 126.395C148.615 126.395 146.303 128.051 146.303 130.095C146.303 132.139 148.615 133.796 151.467 133.796Z" fill="#A67C52" stroke="black" strokeWidth="0.57" strokeMiterlimit="10"/>
                <path id="Vector_72" d="M99.1826 131.787C100.074 131.787 100.796 130.981 100.796 129.987C100.796 128.993 100.074 128.187 99.1826 128.187C98.2913 128.187 97.5688 128.993 97.5688 129.987C97.5688 130.981 98.2913 131.787 99.1826 131.787Z" fill="black" stroke="black" strokeWidth="0.42" strokeMiterlimit="10"/>
                <path id="Vector_73" d="M151.467 131.787C152.358 131.787 153.08 130.981 153.08 129.987C153.08 128.993 152.358 128.187 151.467 128.187C150.575 128.187 149.853 128.993 149.853 129.987C149.853 130.981 150.575 131.787 151.467 131.787Z" fill="black" stroke="black" strokeWidth="0.42" strokeMiterlimit="10"/>
                <path id="Vector_74" d="M70.5291 131.845V136.755C70.527 137.18 70.5704 137.603 70.6582 138.015C70.6582 138.087 70.7615 138.534 70.9164 138.534C71.0713 138.534 71.1359 138.095 71.1552 138.001C71.211 137.584 71.2347 137.163 71.2262 136.741V131.824C71.2283 131.399 71.185 130.976 71.0971 130.564C71.0971 130.499 70.9939 130.045 70.839 130.052C70.684 130.06 70.6195 130.492 70.6001 130.578C70.5442 130.997 70.5204 131.421 70.5291 131.845Z" fill="black"/>
                <path id="Vector_75" d="M67.599 132.219C67.8002 133.867 67.9208 135.525 67.9605 137.187C67.9605 137.381 67.9605 137.569 67.9605 137.763C67.9605 138.375 67.9605 137.432 67.9605 138.051C67.9604 138.355 67.9389 138.658 67.8959 138.958C67.7994 139.668 67.5802 140.35 67.2505 140.967C66.9991 141.666 66.9319 142.43 67.0568 143.17C67.0575 143.535 67.1188 143.896 67.2375 144.236C67.2827 144.322 67.4054 144.617 67.5151 144.409C68.5737 142.414 68.6705 140.002 68.6576 137.741C68.6257 135.287 68.4575 132.836 68.1541 130.405C68.1218 130.16 67.9218 129.116 67.6571 129.778C67.4562 130.573 67.4318 131.411 67.5861 132.219H67.599Z" fill="black"/>
                <path id="Vector_76" d="M65.9976 132.824L66.7206 141.104C66.7206 141.334 66.7722 142.278 67.0433 142.371C67.3144 142.465 67.3596 141.651 67.3725 141.507C67.6414 138.492 67.6781 135.457 67.4822 132.435C67.4822 132.198 67.4241 131.254 67.1595 131.161C66.8948 131.067 66.8432 131.881 66.8303 132.025C66.5636 134.822 66.4837 137.638 66.5915 140.449C66.5915 140.737 66.5914 142.191 66.9787 142.227C67.366 142.263 67.295 140.55 67.2886 140.434C67.2168 138.753 67.2556 137.069 67.4048 135.394L66.7593 134.509V142.486C66.7593 142.767 66.7593 144.229 67.153 144.265C67.5468 144.301 67.4628 142.587 67.4628 142.472V134.523C67.4643 134.16 67.4297 133.798 67.3596 133.443C67.3596 133.306 67.2886 132.882 67.1659 132.81C66.8497 132.615 66.8045 133.443 66.7916 133.638C66.5883 135.9 66.5301 138.177 66.6173 140.449H67.3144C67.2258 138.239 67.2883 136.024 67.5016 133.825L66.8561 133.414C66.8561 133.544 66.8561 133.681 66.8561 133.81C66.8948 134.444 66.8561 133.659 66.8561 134.026C66.8561 134.394 66.8561 134.617 66.8884 134.912C66.9207 135.207 66.8884 135.51 66.8884 135.805V136.417C66.8884 137.598 66.8109 138.577 66.7076 139.736L67.3531 140.146L66.6237 131.874C66.6237 131.65 66.5463 130.434 66.1655 130.635C66.0106 130.736 65.9912 131.355 65.9783 131.463C65.9395 131.921 65.9395 132.381 65.9783 132.838L65.9976 132.824Z" fill="black"/>
                <path id="Vector_77" d="M113.557 134.847C108.819 137.931 103.346 139.316 97.8784 138.815C97.575 138.815 97.4847 141.45 97.943 141.493C103.408 141.995 108.88 140.61 113.615 137.526C113.919 137.324 113.861 136.511 113.848 136.179C113.848 136.086 113.725 134.739 113.557 134.847Z" fill="#8C6239"/>
                <path id="Vector_78" d="M141.468 136.532C144.26 138.052 147.182 139.258 150.189 140.132C150.421 140.204 150.421 138.915 150.421 138.785C150.421 138.533 150.421 137.547 150.124 137.453C147.119 136.58 144.2 135.374 141.41 133.853C141.217 133.745 141.171 135.099 141.178 135.2C141.178 135.516 141.178 136.352 141.468 136.532Z" fill="#8C6239"/>
                <path id="Vector_79" d="M71.1104 130.355C71.1104 130.355 74.015 162.394 72.4013 164.914L74.9833 174.994C74.9833 174.994 74.015 183.274 74.3378 184.354C74.3378 184.354 73.3696 183.994 71.7558 181.474C71.7558 181.474 69.4966 179.674 70.4649 172.834C70.6387 171.16 70.6387 169.468 70.4649 167.794C70.4649 167.794 67.5602 167.434 67.2375 156.274C65.3658 151.995 64.2691 147.347 64.01 142.595C64.01 142.595 62.3963 140.622 64.6555 130.988C64.6555 130.988 64.3263 127.115 71.1104 130.355Z" fill="#E4A57A" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_80" d="M184.063 129.627L186.039 127.467H189.227L192.455 131.067L194.391 141.147L192.19 149.672L186.645 166.347L183.418 179.306L181.481 181.466L184.063 129.627Z" fill="#E4A57A" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <g id="Vector_81">
                <path d="M120.89 206.954C120.89 206.954 121.742 212.073 124.983 215.313C125.654 215.628 126.375 215.79 127.103 215.79C127.831 215.79 128.552 215.628 129.223 215.313C129.223 215.313 135.898 208.552 134.968 206.716" fill="black"/>
                <path d="M120.89 206.954C120.89 206.954 121.742 212.073 124.983 215.313C125.654 215.628 126.375 215.79 127.103 215.79C127.831 215.79 128.552 215.628 129.223 215.313C129.223 215.313 135.898 208.552 134.968 206.716" stroke="black" strokeMiterlimit="10"/>
                </g>
                </g>
                </g>
                </svg>
            </div>

            <div className="content__bobble--small">
            <svg width="125" height="300" viewBox="0 0 125 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="smallBobbleHead">
                <g id="standboard">
                <path id="Vector" d="M29.1953 203.464L16.922 239.544L16.9032 242.117L19.211 242.128L19.5703 255.502C19.5703 255.502 18.9406 257.364 20.8041 256.828L106.651 255.405C106.651 255.405 107.881 255.402 107.894 254.073L109.229 240.251L111.382 239.996C111.382 239.996 112.307 240.791 112.016 237.337L95.0542 202.471L29.1953 203.464Z" fill="#C69C6D" stroke="black" strokeMiterlimit="10"/>
                <path id="Vector_2" d="M19.0451 242.174L109.103 240.282" stroke="black" strokeMiterlimit="10"/>
                <path id="Vector_3" d="M111.951 237.347L17.0165 239.588" stroke="black" strokeMiterlimit="10"/>
                </g>
                <g id="body">
                <path id="Vector_4" d="M92.1089 213.532C92.1089 213.532 93.2772 219.256 92.054 220.955C92.054 220.955 91.1116 225.567 80.2061 226.294C80.2061 226.294 74.145 227.482 64.8306 218.255L64.2303 217.006L62.5553 218.446C62.5553 218.446 53.696 229.584 46.1567 226.593C46.1567 226.593 37.9784 227.349 36.5293 218.612C36.5862 216.905 36.9061 215.222 37.4749 213.637C37.4749 213.637 41.2091 200.382 46.6763 197.941C64.1593 207.661 64.1948 204.568 64.1948 204.568C78.1438 202.858 81.9457 195.99 81.9457 195.99L86.4383 202.264C86.4383 202.264 91.8539 207.297 92.1089 213.532ZM82.049 201.292C82.2419 201.178 82.4035 201.007 82.5171 200.799C82.6306 200.59 82.6919 200.352 82.6945 200.108C82.687 199.858 82.6104 199.617 82.4752 199.418C82.3399 199.219 82.1527 199.071 81.9393 198.996C81.5425 198.905 81.1301 198.957 80.7612 199.143C79.4979 199.646 78.3308 200.409 77.3208 201.393L77.0723 201.753C77.0388 201.908 77.0365 202.069 77.0654 202.225C77.0943 202.381 77.1537 202.528 77.2395 202.655C77.3252 202.782 77.4351 202.887 77.5613 202.961C77.6875 203.036 77.8269 203.078 77.9695 203.085C78.4891 203.085 78.9377 202.65 79.4122 202.326C79.8828 201.995 80.4016 201.759 80.9452 201.627C81.3265 201.574 81.6986 201.458 82.049 201.282V201.292ZM51.7111 205.778L51.6111 205.238C51.085 205.18 50.617 204.766 50.1555 204.432C49.4021 203.877 48.5592 203.49 47.6736 203.294C47.1605 203.182 46.5214 203.211 46.26 203.834C46.6312 204.335 47.099 204.736 47.6284 205.008L49.6294 206.232C49.9757 206.489 50.3761 206.642 50.7913 206.674C51.015 206.665 51.2289 206.57 51.3966 206.404C51.5643 206.239 51.6755 206.014 51.7111 205.767V205.778Z" fill="#A3BCB6"/>
                <path id="Vector_5" d="M92.112 137.613L92.4541 138.654C92.4541 138.654 91.4633 139.597 92.3637 140.436C92.3187 140.707 92.2506 140.974 92.1604 141.231C91.9797 141.764 91.6537 142.311 91.1405 142.084L90.8178 142.912C90.3466 143.517 82.562 139.226 82.562 139.226C83.1849 140.81 80.3351 150.091 80.3351 150.091C79.5558 150.837 78.7343 151.527 77.8758 152.157C64.0334 162.237 50.3878 147.315 50.3878 147.315C50.3878 147.315 46.8022 141.285 46.97 138.866L46.8376 138.934L46.5407 137.786C45.3146 137.164 43.9627 136.916 42.6226 137.066L42.0901 136.45C40.4062 136.542 38.7188 136.386 37.0714 135.986L36.852 135.943C36.852 135.943 35.9451 135.518 36.8713 133.448V131.371C36.8713 131.371 36.6874 118.494 42.4709 112.943C42.4709 112.943 52.7987 107.435 59.444 109.159L66.5443 108.918C77.2916 106.222 87.4161 113.368 87.4161 113.368C87.4161 113.368 92.7478 128.372 92.4057 133.768C92.4057 133.768 92.6865 136.677 92.0829 137.502L92.112 137.613Z" fill="#39603D"/>
                <path id="Vector_6" d="M82.5621 139.226C82.5621 139.226 90.3466 143.528 90.8307 142.927C90.7727 143.168 88.9201 150.847 86.5093 154.087L85.3022 153.658C85.3022 153.658 80.929 151.333 80.3352 150.08C80.3352 150.08 83.185 140.799 82.5621 139.226Z" fill="#E4A57A"/>
                <path id="Vector_7" d="M86.7999 155.75C86.7999 155.75 87.0968 156.581 86.177 157.82L85.383 157.751C83.95 155.642 81.6101 155.332 79.8157 153.834C79.3687 153.448 78.9499 153.024 78.5634 152.564L77.876 152.146C78.7345 151.516 79.5559 150.826 80.3353 150.08C80.9291 151.332 85.3023 153.658 85.3023 153.658L86.7999 155.75Z" fill="#3C403D"/>
                <path id="Vector_8" d="M86.1769 157.82L85.8541 158.648L80.9355 168.155L80.6289 168.983C80.6289 168.983 80.6031 172.306 79.9834 173.13C79.9834 173.13 78.9894 184.751 81.0485 192.246L82.2394 195.162L81.9167 195.99C81.9167 195.99 78.1018 202.858 64.1658 204.568C64.1658 204.568 64.1303 207.661 46.6474 197.941L47.2606 197.117L47.877 195.461L47.2897 193.38C47.2897 193.38 47.2897 192.962 48.8259 190.071C48.8259 190.071 48.5709 183.836 49.1777 183.844L49.2874 169.307C48.9497 168.778 48.6507 168.219 48.3934 167.637C48.3934 167.637 43.2877 162.19 43.3167 158.453C43.3167 158.453 42.413 158.028 42.7261 156.369C42.9107 156.233 43.0632 156.05 43.1705 155.834C43.2778 155.618 43.3369 155.377 43.3425 155.13C43.3425 155.13 49.4552 147.297 50.3654 147.304C50.3654 147.304 64.0109 162.24 77.8533 152.146L78.5408 152.564C78.9273 153.024 79.346 153.449 79.793 153.834C81.5875 155.332 83.9273 155.634 85.3603 157.751L86.1769 157.82Z" fill="#3C403D"/>
                <path id="Vector_9" d="M82.6881 200.097C82.6855 200.341 82.6242 200.58 82.5107 200.788C82.3971 200.996 82.2355 201.167 82.0426 201.282C81.6909 201.463 81.3166 201.585 80.9324 201.642C80.3888 201.773 79.87 202.009 79.3993 202.34C78.9346 202.664 78.4763 203.089 77.9567 203.1C77.8141 203.092 77.6747 203.05 77.5485 202.976C77.4223 202.901 77.3124 202.797 77.2266 202.669C77.1409 202.542 77.0814 202.395 77.0525 202.239C77.0236 202.083 77.026 201.922 77.0594 201.768L77.308 201.408C78.3179 200.423 79.4851 199.66 80.7484 199.158C81.1172 198.971 81.5296 198.919 81.9264 199.01C82.1376 199.083 82.3237 199.226 82.4599 199.421C82.596 199.615 82.6756 199.851 82.6881 200.097V200.097Z" fill="#A67C52"/>
                <path id="Vector_10" d="M51.6111 205.227L51.7112 205.767C51.6735 206.012 51.5615 206.234 51.3939 206.398C51.2264 206.561 51.0136 206.655 50.7913 206.664C50.3761 206.631 49.9757 206.478 49.6295 206.221L47.6284 204.997C47.099 204.725 46.6312 204.324 46.26 203.823C46.5214 203.2 47.1605 203.172 47.6736 203.283C48.5592 203.479 49.4022 203.866 50.1555 204.421C50.6171 204.745 51.085 205.17 51.6111 205.227Z" fill="#A67C52"/>
                <path id="Vector_11" d="M46.9701 138.866C46.799 141.271 50.3879 147.29 50.3879 147.29C49.4778 147.29 43.365 155.116 43.365 155.116H42.4581C42.4581 155.116 39.1597 150.933 37.1006 142.815C39.0596 143.024 45.8146 139.482 46.8377 138.945L46.9701 138.866Z" fill="#E4A57A"/>
                <path id="Vector_12" d="M46.5408 137.786L46.8377 138.934C45.834 139.485 39.0564 143.028 37.1005 142.826C37.1005 142.826 36.4906 143.442 36.823 139.708C36.823 139.708 35.9225 138.452 36.2324 137.624C36.2324 137.624 35.929 137.624 36.852 135.972L37.0715 136.015C38.7188 136.415 40.4062 136.571 42.0902 136.479L42.6227 137.095C43.9605 136.936 45.3122 137.174 46.5408 137.786V137.786Z" fill="#39603D"/>
                <path id="Vector_13" d="M90.8308 142.912L91.1535 142.084C91.6667 142.325 91.9926 141.764 92.1734 141.231C92.2636 140.974 92.3317 140.707 92.3767 140.435C91.4763 139.597 92.4671 138.653 92.4671 138.653L92.125 137.613L92.0959 137.523C92.7091 136.699 92.4187 133.79 92.4187 133.79C92.7608 128.39 87.4291 113.389 87.4291 113.389C87.4291 113.389 77.3046 106.243 66.5573 108.94L59.457 109.181C52.8085 107.456 42.4839 112.964 42.4839 112.964C36.7004 118.515 36.8844 131.392 36.8844 131.392V133.469C35.9581 135.539 36.865 135.964 36.865 135.964C35.942 137.617 36.2453 137.617 36.2453 137.617C35.9226 138.445 36.8359 139.701 36.8359 139.701C36.5132 143.434 37.1135 142.819 37.1135 142.819C39.1726 150.936 42.471 155.119 42.471 155.119H43.3779C43.3723 155.366 43.3133 155.607 43.2059 155.823C43.0986 156.039 42.9461 156.222 42.7615 156.358C42.4387 158.017 43.3521 158.442 43.3521 158.442C43.3231 162.179 48.4288 167.626 48.4288 167.626C48.6861 168.208 48.9851 168.767 49.3229 169.296L49.2131 183.833C48.6064 183.833 48.8613 190.06 48.8613 190.06C47.3283 192.94 47.3251 193.369 47.3251 193.369L47.9125 195.45L47.296 197.106L46.6828 197.93C41.2156 200.371 37.4814 213.626 37.4814 213.626C36.9126 215.211 36.5927 216.894 36.5358 218.601C37.9849 227.338 46.1632 226.582 46.1632 226.582C53.7089 229.563 62.5714 218.432 62.5714 218.432L64.2465 216.992L64.8435 218.241C74.1579 227.467 80.219 226.279 80.219 226.279C91.1245 225.559 92.0669 220.941 92.0669 220.941C93.2901 219.242 92.1217 213.518 92.1217 213.518C91.8668 207.283 86.4576 202.25 86.4576 202.25L81.965 195.975L82.2878 195.147L81.0969 192.231C79.0378 184.736 80.0318 173.116 80.0318 173.116C80.645 172.291 80.6773 168.968 80.6773 168.968L80.9839 168.14L85.8702 158.665L86.193 157.837C87.1128 156.599 86.8158 155.767 86.8158 155.767L85.3183 153.676L86.5254 154.104C88.9363 150.864 90.7888 143.175 90.8469 142.945" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_14" d="M43.365 155.131C43.365 155.131 49.4778 147.297 50.3879 147.304C50.3879 147.304 46.799 141.285 46.9701 138.88L46.8377 138.949C45.834 139.485 39.0564 143.028 37.1006 142.826" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_15" d="M85.3022 153.658C85.3022 153.658 80.929 151.333 80.3352 150.08C80.3352 150.08 83.185 140.799 82.5621 139.215C82.5621 139.215 90.3466 143.517 90.8307 142.916" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_16" d="M81.9522 195.99C81.9522 195.99 78.1373 202.858 64.2013 204.568C64.2013 204.568 64.1658 207.661 46.6829 197.941" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_17" d="M50.3879 147.304C50.3879 147.304 64.0335 162.241 77.8759 152.146C78.7344 151.517 79.5558 150.826 80.3352 150.08" stroke="black" strokeWidth="1.04" strokeMiterlimit="10"/>
                <path id="Vector_18" d="M80.7999 194.345C78.8312 196.335 76.4171 197.253 74.0611 198.135L65.2986 201.415" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_19" d="M63.614 202.059C60.5028 201.487 57.3722 200.904 54.4029 199.539C52.4116 198.618 50.4558 197.322 48.3547 197.235" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_20" d="M64.4272 173.281C64.1529 173.665 63.8975 174.065 63.6623 174.48C62.6683 175.981 60.9384 176.42 60.1799 178.163" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_21" d="M64.3659 170.7C63.3415 172.009 62.2544 173.255 61.1094 174.433" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_22" d="M64.6147 166.891C63.2914 167.917 61.9746 168.943 60.6514 169.965" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_23" d="M63.982 165.707C62.6407 166.28 61.3732 167.049 60.2124 167.993" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_24" d="M64.8177 156.354C64.2966 158.623 64.3978 161.013 65.1082 163.216" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_25" d="M69.3106 156.83C69.2764 158.543 69.1329 160.251 68.8813 161.941" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_26" d="M66.5283 171.089C67.1545 171.769 67.545 172.774 68.0549 173.609C68.5649 174.444 69.3298 175.182 70.1141 174.966" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_27" d="M65.2698 164.785C64.5437 164.641 63.9628 163.914 63.398 163.27C62.5546 162.301 61.6221 161.433 60.616 160.682" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_28" d="M78.5635 152.563C78.95 153.023 79.3687 153.448 79.8157 153.834C81.6101 155.332 83.9499 155.634 85.3829 157.751" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_29" d="M82.1359 153.114L79.8767 153.269" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_30" d="M50.9624 149.511C50.2012 151.021 49.207 152.371 48.0255 153.496C46.5312 154.903 44.7981 155.829 43.4167 157.456" stroke="black" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_31" d="M73.9839 202.441C75.0802 204.693 76.6261 206.635 78.5022 208.114" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_32" d="M77.6439 209.281C75.9675 208.843 74.2448 208.668 72.5251 208.759" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_33" d="M77.0658 201.757C77.0323 201.911 77.03 202.073 77.0589 202.228C77.0878 202.384 77.1472 202.531 77.233 202.658C77.3187 202.786 77.4286 202.89 77.5548 202.965C77.681 203.039 77.8204 203.081 77.963 203.089C78.4826 203.089 78.9313 202.653 79.4057 202.329C79.8763 201.998 80.3952 201.762 80.9387 201.631C81.3229 201.574 81.6972 201.452 82.049 201.271C82.2418 201.156 82.4035 200.985 82.517 200.777C82.6306 200.569 82.6919 200.33 82.6945 200.086C82.687 199.837 82.6104 199.596 82.4751 199.396C82.3399 199.197 82.1527 199.05 81.9392 198.974C81.5424 198.883 81.13 198.935 80.7612 199.121C79.4979 199.624 78.3307 200.387 77.3207 201.371" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_34" d="M80.0255 203.47C81.1645 202.894 82.3404 202.413 83.5434 202.03C83.59 202.006 83.6414 201.995 83.6928 201.999C83.7441 202.003 83.7938 202.021 83.8371 202.052C84.034 202.257 83.7758 202.657 83.5466 202.79L81.139 204.208C80.5548 204.568 79.948 204.903 79.3122 204.892C79.2421 204.899 79.1717 204.886 79.1071 204.855C79.0424 204.825 78.9854 204.777 78.9411 204.716C78.8909 204.61 78.8711 204.489 78.8844 204.37C78.8977 204.251 78.9433 204.139 79.0153 204.05C79.152 203.906 79.3219 203.808 79.5059 203.766" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_35" d="M56.0842 203.985C54.6698 204.863 53.4995 206.158 52.7019 207.729" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_36" d="M52.1501 209.36C53.2763 210.384 54.6222 211.062 56.0553 211.329" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_37" d="M54.2769 202.945C52.6518 202.105 50.9797 201.384 49.2712 200.785C49.1499 200.721 49.0182 200.687 48.8843 200.683C48.7504 200.679 48.6173 200.707 48.4934 200.763C48.4305 200.809 48.3766 200.869 48.335 200.939C48.2934 201.008 48.2649 201.087 48.2513 201.169C48.2377 201.252 48.2393 201.337 48.256 201.418C48.2726 201.5 48.304 201.577 48.3481 201.645C48.3982 201.693 48.4563 201.73 48.5192 201.753L52.6987 203.625C53.1441 203.823 53.7638 203.924 53.9574 203.341" stroke="#754C24" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_38" d="M51.6111 205.227C51.085 205.17 50.6171 204.756 50.1555 204.421C49.4022 203.866 48.5592 203.479 47.6736 203.283C47.1605 203.172 46.5214 203.2 46.26 203.823C46.6312 204.324 47.099 204.725 47.6284 204.997L49.6295 206.221C49.9757 206.478 50.3761 206.631 50.7913 206.664C51.0136 206.655 51.2264 206.561 51.3939 206.398C51.5615 206.234 51.6735 206.012 51.7112 205.767" stroke="#A3BCB6" strokeWidth="0.52" strokeMiterlimit="10"/>
                <path id="Vector_39" d="M63.6108 213.251C61.1386 215.147 58.6643 217.045 56.1877 218.943C55.4117 219.596 54.5688 220.143 53.6768 220.574C53.0313 220.855 52.3407 220.995 51.692 221.294C50.7754 221.74 49.9621 222.518 49.0487 222.982C46.1795 224.422 43.0167 222.554 40.4121 220.376C39.0502 219.231 37.643 217.856 37.146 215.757" stroke="#42210B" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_40" d="M64.8757 213.564C70.1784 218.187 76.1427 221.754 82.4232 222.24C84.3306 222.388 86.2961 222.24 88.0777 221.319C89.0414 220.82 89.9058 220.111 90.6198 219.234C91.3338 218.357 91.8829 217.328 92.2346 216.21" stroke="#42210B" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_41" d="M91.7571 132.663C90.5694 134.737 88.5587 135.86 86.6577 135.518" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_42" d="M92.1861 135.237C91.2663 136.072 90.2883 136.846 89.2071 137.134C88.6584 137.303 88.0783 137.298 87.5321 137.119C86.9859 136.941 86.4952 136.596 86.1152 136.123" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_43" d="M92.1409 137.588C92.1299 137.594 92.1201 137.603 92.1119 137.613C91.1735 138.411 90.0577 138.909 88.8845 139.053" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_44" d="M92.27 141.231H92.1602C91.0083 141.202 89.892 140.78 88.9619 140.021" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_45" d="M83.4883 134.593C84.2306 135.19 84.96 135.82 85.6668 136.486" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_46" d="M37.4717 139.996C39.2246 139.942 40.9434 139.442 42.4968 138.535" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_47" d="M37.0718 136.008C38.7191 136.408 40.4065 136.564 42.0904 136.472" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_48" d="M46.5409 137.786C45.3147 137.164 43.9629 136.916 42.6228 137.066" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_49" d="M50.675 139.982C51.8215 141.824 53.0674 143.586 54.406 145.259" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_50" d="M49.8911 141.354C51.0558 143.101 52.0745 144.964 52.9346 146.919" stroke="black" strokeWidth="0.26" strokeMiterlimit="10"/>
                <path id="Vector_51" d="M65.0079 166.175C65.4985 172.749 64.0397 179.275 63.7751 185.824C63.7046 187.559 63.723 189.297 63.83 191.029C63.9332 192.685 64.1204 194.334 64.1914 195.99C64.3302 199.273 64.043 202.553 63.8687 205.825C63.6654 209.583 63.617 213.385 64.285 217.075C64.3431 217.402 64.4787 217.359 64.5367 217.075C64.6072 216.646 64.5984 216.207 64.5109 215.782C63.9494 212.665 64.0526 209.464 64.2237 206.307C64.4012 203.042 64.6658 199.773 64.5722 196.497C64.4786 193.222 64.0107 189.953 64.1236 186.648C64.2366 183.343 64.6529 180.129 64.9853 176.878C65.3662 173.163 65.6308 169.422 65.3468 165.686C65.3468 165.56 65.3113 164.948 65.1112 165.056C64.9111 165.164 65.0015 166.031 65.0111 166.182L65.0079 166.175Z" fill="black"/>
                </g>
                <g id="face">
                <path id="Vector_52" d="M37.169 92.177C37.169 92.177 43.3011 126.038 63.9566 123.687C63.9566 123.687 85.2576 128.536 90.7442 90.737L91.7124 89.657L93.3261 83.1771L96.0985 74.8397L97.199 70.5774L96.2308 65.5375L94.6171 63.7375H93.0227L92.0351 64.8175L93.0034 55.8176V45.0178L92.6806 43.2179L91.7124 36.378L89.7759 34.578L91.0669 30.9781C91.0669 30.9781 92.6806 23.4182 84.9348 15.8584C84.9348 15.8584 76.5435 7.93852 74.6071 7.57853C74.6071 7.57853 68.475 7.21854 69.1204 6.13856C69.1204 6.13856 69.4432 7.17534 63.9566 5.39697L60.7291 6.13856L57.179 7.21854L54.9198 7.93852H53.3061L52.3378 8.29852L51.0469 9.0185L49.1104 9.3785L47.8195 10.0985L46.5285 11.1785L45.2375 11.8985L42.6556 12.6184L42.0101 13.6984L40.7191 14.4184L39.7509 15.8584L38.7827 16.9384L37.8144 17.2984L37.169 18.4827L36.5235 20.1783L35.2325 20.5383L33.6188 23.7782L32.3278 27.7382V30.9349L33.2961 32.0581L32.3278 32.8429L33.6188 33.8616L32.3278 35.1324V39.1967L32.9733 41.7815L33.6188 43.9415L33.9415 46.4614L33.6188 52.5813V56.1812L34.587 60.5012L35.5553 65.1811C35.5553 65.1811 32.3278 63.6547 32.3278 65.4979C32.3278 65.4979 31.3596 69.501 32.0051 71.3009C32.0724 73.6877 32.6241 76.0263 33.6188 78.1408C33.6188 78.1408 33.6188 83.5407 35.2325 83.9007C35.2325 83.9007 35.5553 85.3407 35.2325 86.4207C35.2325 86.4207 34.587 89.3006 35.878 90.7406L37.169 92.177Z" fill="black" stroke="black" strokeMiterlimit="10"/>
                <path id="Vector_53" d="M90.0989 66.6177L90.7056 77.4175L89.7761 84.2142L87.4621 92.0081C87.4621 92.0081 84.935 97.2172 83.3213 97.9371L79.7711 99.7371L76.8664 93.6172L72.9935 91.0973L68.8205 89.4665L64.4925 88.1093L61.0521 88.2173L57.8246 88.9373L55.5654 90.7373L51.6925 92.5372L50.4016 96.1372L49.188 100.479L48.4651 104.057L42.333 94.3372L37.4919 87.4973L36.2009 82.4574V74.84L35.5554 65.1777L36.3332 56.5811L39.5606 49.3164L40.7193 38.8982C40.7193 38.8982 42.9785 32.0583 45.5604 32.0583L53.9517 30.2583L61.6975 28.8184L66.8614 29.5383L69.4434 28.8184L73.9617 29.8983L78.1574 31.0179L79.4484 30.2583L82.6758 32.7783L84.935 37.8182L87.4588 42.2029L88.5174 52.0163L90.0989 66.6177Z" fill="#E4A57A" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_54" d="M68.9368 57.1568C68.9368 57.1568 68.1751 54.1977 70.4956 53.8017C70.4956 53.8017 70.7538 52.3869 82.7082 55.5368L87.459 59.1224C85.3354 60.6271 68.9368 57.1568 68.9368 57.1568Z" fill="black" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_55" d="M58.8089 56.2749C58.8089 56.2749 54.0453 52.8262 53.2804 53.7945C53.2804 53.7945 49.4301 52.531 39.5574 58.9064C39.5574 58.9064 53.0803 61.4804 58.8089 56.2749Z" fill="black" stroke="black" strokeWidth="0.75" strokeMiterlimit="10"/>
                <path id="Vector_56" d="M70.4114 65.5375C70.4114 65.5375 75.9368 59.7776 82.0495 65.5375C82.0463 65.5375 73.6388 68.7774 70.4114 65.5375Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_57" d="M56.3689 65.7177C56.3689 65.7177 49.2492 59.9577 43.782 65.7177C43.7723 65.7177 50.2368 68.9577 56.3689 65.7177Z" fill="white" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_58" d="M58.0505 60.9691C58.6476 60.9439 58.9994 61.5199 59.1962 62.0779C59.4403 62.8471 59.6018 63.6458 59.6771 64.4574C59.86 66.0638 59.7846 67.6937 59.4544 69.2705C59.1317 70.8185 58.5734 72.2873 58.1635 73.8029C57.7226 75.3113 57.542 76.8993 57.631 78.4828C57.631 78.5512 57.6665 79.0516 57.802 78.9472C57.9376 78.8428 57.8827 78.2632 57.873 78.1192C57.7601 76.4164 58.3797 74.71 58.8735 73.1297C59.415 71.515 59.7812 69.8339 59.9644 68.1222C60.1191 66.3568 60.0452 64.5758 59.7449 62.8339C59.5739 61.8475 59.2866 59.5939 58.0215 59.6479C57.8278 59.6479 57.8601 60.9979 58.0505 60.9871V60.9691Z" fill="black"/>
                <path id="Vector_59" d="M55.8879 79.5591C55.194 80.3187 54.5034 81.1215 54.3065 82.2231C54.1985 82.7044 54.1983 83.208 54.3058 83.6895C54.4133 84.1709 54.6252 84.6156 54.9229 84.9842C55.6357 85.7185 56.4348 86.3411 57.2983 86.8346C58.1787 87.4332 59.1746 87.7887 60.203 87.8714C60.316 87.8714 60.3257 86.9822 60.1837 86.9786C58.4957 86.8994 57.024 85.8374 55.6846 84.7826C55.0908 84.3146 54.2581 83.6054 54.4872 82.6875C54.7164 81.7695 55.3683 81.0675 55.9622 80.4195C56.059 80.3151 56.0235 80.0019 56.0106 79.8687C56.0106 79.8255 55.9719 79.4799 55.8976 79.5591H55.8879Z" fill="black"/>
                <path id="Vector_60" d="M69.4434 78.1589L70.8893 81.1541C71.0732 81.5393 71.5089 82.1585 71.4831 82.6192C71.4573 83.08 71.0281 83.62 70.7634 83.9692C69.6693 85.4092 68.0878 86.8996 66.274 86.2336C66.2288 86.2156 66.1061 87.058 66.2933 87.1264C66.7853 87.2828 67.3028 87.3115 67.8063 87.2104C68.3098 87.1093 68.7858 86.8811 69.1981 86.5432C70.096 85.8846 70.8432 85.0006 71.3798 83.962C71.5751 83.5174 71.6704 83.0265 71.6575 82.5316C71.6446 82.0367 71.5238 81.5528 71.3056 81.1217C70.7247 79.8689 70.105 78.6377 69.5079 77.3957C69.4304 77.2337 69.3982 77.4965 69.3917 77.5577C69.3564 77.7625 69.372 77.9742 69.4369 78.1697L69.4434 78.1589Z" fill="black"/>
                <path id="Vector_61" d="M68.6591 59.5655C67.9383 60.1471 67.3439 60.902 66.9199 61.7743C66.4959 62.6467 66.2531 63.6142 66.2096 64.6054C66.0772 66.8913 66.7421 69.0729 67.4456 71.1861C67.5069 71.3661 67.5586 71.0637 67.5618 71.0205C67.5906 70.8166 67.5752 70.6079 67.5166 70.4121C66.9067 68.5761 66.1708 66.5457 66.4129 64.5514C66.526 63.7235 66.7892 62.93 67.1865 62.2194C67.5838 61.5089 68.1068 60.8961 68.7236 60.4187C68.8721 60.3035 68.7236 59.5043 68.6591 59.5583V59.5655Z" fill="black"/>
                <path id="Vector_62" d="M56.9951 83.8648C57.7148 83.7784 58.4733 83.6812 59.1704 83.9404C59.4527 84.0288 59.7114 84.1927 59.924 84.4179C60.1366 84.6432 60.2967 84.9229 60.3904 85.2328C60.3904 85.2328 60.4162 85.3624 60.4549 85.3588C60.4936 85.3552 60.5098 85.2436 60.513 85.2292C60.5533 85.0204 60.5533 84.8044 60.513 84.5956C60.4346 84.2574 60.2838 83.9454 60.0736 83.6865C59.8633 83.4275 59.5999 83.2293 59.306 83.1088C58.5733 82.7777 57.7535 82.8784 56.9887 82.972C56.8466 82.99 56.8983 83.8792 57.008 83.8648H56.9951Z" fill="black"/>
                <path id="Vector_63" d="M70.1567 82.8246C69.495 82.4926 68.7554 82.4057 68.0462 82.5768C67.337 82.7478 66.6956 83.1677 66.216 83.775C66.1257 83.8974 66.1579 84.1818 66.1676 84.3258C66.1676 84.3654 66.216 84.7218 66.2806 84.6354C66.758 84.0369 67.3949 83.6248 68.0977 83.4595C68.8006 83.2943 69.5324 83.3847 70.1858 83.7174C70.2503 83.7534 70.2632 83.3034 70.2632 83.2674C70.2632 83.163 70.2632 82.8822 70.1632 82.8246H70.1567Z" fill="black"/>
                <path id="Vector_64" d="M60.3614 85.0996C61.3652 86.4856 62.8272 88.2927 64.6216 87.5836C64.7991 87.5116 64.6539 86.6692 64.6022 86.6908C62.8175 87.3928 61.3748 85.6108 60.3873 84.2392C60.3195 84.142 60.2872 84.5308 60.2872 84.5524C60.2611 84.739 60.287 84.93 60.3614 85.0996V85.0996Z" fill="black"/>
                <path id="Vector_65" d="M60.4452 103.477C60.4452 103.477 59.2736 104.557 52.9801 98.2755C52.9801 98.2755 51.1502 102.981 51.4987 104.151C51.4987 104.151 54.0516 112.409 60.3742 114.983C60.3742 114.983 62.4623 113.935 63.1271 114.306L63.792 114.22C64.8339 114.04 65.8979 114.313 66.7645 114.983C66.7645 114.983 74.7846 113.237 76.2273 104.057C76.2273 104.057 75.7335 99.9171 74.6136 97.5771C74.6136 97.5771 69.2496 105.357 67.4939 103.359C67.4939 103.359 64.4472 104.392 63.8017 104.133C62.6593 104.108 61.5272 103.887 60.4452 103.477V103.477Z" fill="#E4A57A"/>
                <path id="Vector_66" d="M72.5512 99.863C71.8279 100.79 71.0002 101.609 70.0887 102.3C69.2431 102.941 68.1716 103.679 67.1195 103.243L67.1582 105.043C67.7714 104.637 68.5105 104.77 69.1721 104.525C69.8244 104.242 70.4185 103.815 70.9181 103.269C72.151 102.034 73.3161 100.705 74.5103 99.4202C74.7039 99.2114 74.6297 98.5815 74.6103 98.3187C74.6103 98.2179 74.5458 97.5519 74.3812 97.6995C72.6849 99.2209 70.8554 100.548 68.9204 101.659C67.9818 102.236 66.9989 102.718 65.9834 103.099C64.9145 103.457 63.7838 103.52 62.6882 103.283L62.7302 105.083L67.7875 104.662C68.0393 104.64 68.0199 102.898 67.7488 102.862L67.4261 102.837C67.2163 102.837 67.1647 104.593 67.4648 104.637L67.7875 104.665L67.7488 102.865L62.6882 103.287C62.4397 103.305 62.4494 105.011 62.7302 105.087C63.8493 105.329 65.0051 105.258 66.0932 104.878C67.1283 104.482 68.1305 103.985 69.0882 103.395C71.0079 102.277 72.824 100.95 74.5103 99.4346L74.3812 97.7139C73.2193 98.9667 72.0768 100.234 70.8859 101.465C70.3881 102.027 69.7899 102.466 69.1302 102.754C68.4847 102.999 67.7327 102.865 67.1195 103.272C66.8613 103.445 66.8613 104.935 67.1582 105.072C68.2039 105.504 69.256 104.777 70.1016 104.151C71.0584 103.442 71.9266 102.594 72.6835 101.631C72.8675 101.397 72.803 100.817 72.7804 100.529C72.7804 100.453 72.6868 99.7406 72.5512 99.9134V99.863Z" fill="#D39972"/>
                <path id="Vector_67" d="M63.9567 102.548C62.8691 102.455 61.7815 102.365 60.6971 102.264C59.6127 102.163 58.6444 101.961 57.7601 101.27C56.2917 100.126 54.9717 98.0303 52.9481 98.3399C52.6479 98.3903 52.7835 100.158 52.9868 100.14C55.0814 99.8051 56.3627 102.069 57.9247 103.171C58.7586 103.714 59.6999 104.018 60.6648 104.057C61.775 104.165 62.8852 104.251 63.9955 104.348C64.202 104.366 64.2569 102.591 63.9567 102.548Z" fill="#D39972"/>
                <path id="Vector_68" d="M52.9834 97.5772C52.9834 97.5772 58.1279 92.0909 59.9127 92.8541C59.9127 92.8541 63.3111 94.5064 63.9566 93.7001C63.9566 93.7001 62.9497 94.8268 67.4874 92.7821C67.4874 92.7821 73.3161 96.8572 74.6071 96.4972C74.6071 96.4972 72.4835 98.0632 66.2836 96.9184C66.2836 96.9184 66.2836 96.8572 61.0907 97.5772C61.0907 97.5772 56.9725 96.4972 55.6234 97.5772H52.9834Z" fill="#DC8D88" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_69" d="M74.6071 97.5771C74.6071 97.5771 69.4045 104.457 67.4874 103.359C67.4874 103.359 63.0691 105.058 60.4484 103.477C60.4484 103.477 59.1155 104.734 52.9834 98.2755C52.9834 98.2755 56.3786 99.0171 57.263 97.5771C57.263 97.5771 60.4065 98.2971 61.0519 98.2971L65.4961 97.5771L70.1726 98.2755L74.6071 97.5771Z" fill="#DC8D88" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_70" d="M49.5917 66.8985C51.0177 66.8985 52.1737 66.0701 52.1737 65.0481C52.1737 64.0262 51.0177 63.1978 49.5917 63.1978C48.1658 63.1978 47.0098 64.0262 47.0098 65.0481C47.0098 66.0701 48.1658 66.8985 49.5917 66.8985Z" fill="#8C6239" stroke="black" strokeWidth="0.57" strokeMiterlimit="10"/>
                <path id="Vector_71" d="M75.7336 66.8985C77.1596 66.8985 78.3155 66.0701 78.3155 65.0481C78.3155 64.0262 77.1596 63.1978 75.7336 63.1978C74.3076 63.1978 73.1516 64.0262 73.1516 65.0481C73.1516 66.0701 74.3076 66.8985 75.7336 66.8985Z" fill="#A67C52" stroke="black" strokeWidth="0.57" strokeMiterlimit="10"/>
                <path id="Vector_72" d="M49.5913 65.8937C50.0369 65.8937 50.3981 65.4908 50.3981 64.9937C50.3981 64.4967 50.0369 64.0938 49.5913 64.0938C49.1457 64.0938 48.7844 64.4967 48.7844 64.9937C48.7844 65.4908 49.1457 65.8937 49.5913 65.8937Z" fill="black" stroke="black" strokeWidth="0.42" strokeMiterlimit="10"/>
                <path id="Vector_73" d="M75.7336 65.8937C76.1792 65.8937 76.5404 65.4908 76.5404 64.9937C76.5404 64.4967 76.1792 64.0938 75.7336 64.0938C75.288 64.0938 74.9268 64.4967 74.9268 64.9937C74.9268 65.4908 75.288 65.8937 75.7336 65.8937Z" fill="black" stroke="black" strokeWidth="0.42" strokeMiterlimit="10"/>
                <path id="Vector_74" d="M35.2646 65.9228V68.378C35.2635 68.5901 35.2852 68.8016 35.3291 69.0079C35.3291 69.0439 35.3807 69.2671 35.4582 69.2671C35.5357 69.2671 35.5679 69.0475 35.5776 69.0007C35.6055 68.7923 35.6174 68.5815 35.6131 68.3708V65.912C35.6142 65.6999 35.5925 65.4884 35.5486 65.282C35.5486 65.2496 35.4969 65.0228 35.4195 65.0264C35.342 65.03 35.3097 65.246 35.3001 65.2892C35.2721 65.4989 35.2602 65.7109 35.2646 65.9228V65.9228Z" fill="black"/>
                <path id="Vector_75" d="M33.7997 66.11C33.9003 66.9338 33.9607 67.763 33.9805 68.594C33.9805 68.6912 33.9805 68.7848 33.9805 68.882C33.9805 69.188 33.9805 68.7164 33.9805 69.026C33.9805 69.1779 33.9697 69.3296 33.9482 69.4796C33.9 69.8346 33.7903 70.1757 33.6255 70.484C33.4998 70.8334 33.4662 71.2156 33.5286 71.5856C33.529 71.7679 33.5596 71.9485 33.619 72.1184C33.6416 72.1616 33.7029 72.3092 33.7578 72.2048C34.2871 71.2076 34.3355 70.0016 34.329 68.8712C34.3131 67.6438 34.229 66.4187 34.0773 65.2028C34.0612 65.0804 33.9611 64.5585 33.8288 64.8897C33.7284 65.2872 33.7162 65.7061 33.7933 66.11H33.7997Z" fill="black"/>
                <path id="Vector_76" d="M32.9991 66.4123L33.3605 70.5522C33.3605 70.6674 33.3863 71.139 33.5219 71.1858C33.6574 71.2326 33.68 70.8258 33.6865 70.7538C33.8209 69.2463 33.8393 67.7288 33.7414 66.2179C33.7414 66.0991 33.7123 65.6275 33.58 65.5807C33.4477 65.5339 33.4218 65.9407 33.4154 66.0127C33.282 67.4114 33.2421 68.8193 33.296 70.2246C33.296 70.3686 33.296 71.0958 33.4896 71.1138C33.6833 71.1318 33.6478 70.275 33.6445 70.2174C33.6086 69.377 33.628 68.5347 33.7026 67.6974L33.3799 67.2546V71.2434C33.3799 71.3838 33.3799 72.1146 33.5768 72.1326C33.7736 72.1506 33.7317 71.2938 33.7317 71.2362V67.2618C33.7324 67.0803 33.7151 66.8992 33.68 66.7218C33.68 66.6534 33.6445 66.441 33.5832 66.405C33.4251 66.3078 33.4025 66.7218 33.396 66.819C33.2944 67.9505 33.2653 69.0886 33.3089 70.2246H33.6574C33.6131 69.1196 33.6444 68.0124 33.751 66.9126L33.4283 66.7075C33.4283 66.7722 33.4283 66.8406 33.4283 66.9054C33.4477 67.2222 33.4283 66.8298 33.4283 67.0134C33.4283 67.197 33.4283 67.3086 33.4444 67.4562C33.4606 67.6038 33.4444 67.755 33.4444 67.9026V68.2086C33.4444 68.799 33.4057 69.2886 33.3541 69.8682L33.6768 70.0734L33.3121 65.9371C33.3121 65.8255 33.2734 65.2171 33.083 65.3179C33.0055 65.3683 32.9958 65.6779 32.9894 65.7319C32.97 65.9605 32.97 66.1908 32.9894 66.4195L32.9991 66.4123Z" fill="black"/>
                <path id="Vector_77" d="M56.7789 67.424C54.4099 68.966 51.6731 69.6584 48.9395 69.4076C48.7878 69.4076 48.7426 70.7251 48.9717 70.7467C51.7044 70.9976 54.44 70.3051 56.8079 68.7632C56.9596 68.6624 56.9306 68.2556 56.9241 68.09C56.9241 68.0432 56.8628 67.37 56.7789 67.424V67.424Z" fill="#8C6239"/>
                <path id="Vector_78" d="M70.7344 68.2661C72.1305 69.0265 73.5911 69.6295 75.0946 70.0661C75.2108 70.1021 75.2108 69.4577 75.2108 69.3929C75.2108 69.2669 75.2108 68.7737 75.0624 68.7269C73.5599 68.2903 72.1003 67.6873 70.7053 66.9269C70.6085 66.8729 70.5859 67.5497 70.5892 67.6001C70.5892 67.7585 70.5892 68.1761 70.7344 68.2661Z" fill="#8C6239"/>
                <path id="Vector_79" d="M35.5554 65.1774C35.5554 65.1774 37.0078 81.1971 36.2009 82.457L37.4919 87.4969C37.4919 87.4969 37.0078 91.6369 37.1691 92.1768C37.1691 92.1768 36.685 91.9969 35.8782 90.7369C35.8782 90.7369 34.7486 89.8369 35.2327 86.417C35.3196 85.5798 35.3196 84.7342 35.2327 83.897C35.2327 83.897 33.7803 83.717 33.619 78.1371C32.6831 75.9977 32.1348 73.6734 32.0053 71.2973C32.0053 71.2973 31.1984 70.3109 32.328 65.4942C32.328 65.4942 32.1634 63.5574 35.5554 65.1774Z" fill="#E4A57A" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <path id="Vector_80" d="M92.0322 64.8139L93.0198 63.7339H94.6141L96.2278 65.5339L97.196 70.5738L96.0955 74.8361L93.3231 83.1735L91.7094 89.6534L90.7412 90.7334L92.0322 64.8139Z" fill="#E4A57A" stroke="black" strokeWidth="0.5" strokeMiterlimit="10"/>
                <g id="Vector_81">
                <path d="M60.4453 103.477C60.4453 103.477 60.8713 106.037 62.4915 107.657C62.8272 107.814 63.1876 107.895 63.5517 107.895C63.9158 107.895 64.2763 107.814 64.612 107.657C64.612 107.657 67.9491 104.276 67.4844 103.358" fill="black"/>
                <path d="M60.4453 103.477C60.4453 103.477 60.8713 106.037 62.4915 107.657C62.8272 107.814 63.1876 107.895 63.5517 107.895C63.9158 107.895 64.2763 107.814 64.612 107.657C64.612 107.657 67.9491 104.276 67.4844 103.358" stroke="black" strokeMiterlimit="10"/>
                </g>
                </g>
                </g>
                </svg>
            </div>

            {/* <div className="content__righthand">
                
            </div> */}

        </div>
    );
}

export default Mainpage;